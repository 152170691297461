import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./style/index.css";
import { createBrowserHistory } from "history";

// Layouts and pages

// Providers
import { SoccerProvider } from "./actions/SoccerContext";
import { UserProvider } from "./actions/UserContext";
import { WebsiteRouter } from "./AppRouter";

const history = createBrowserHistory();

function App() {
  return (
    <SoccerProvider>
      <UserProvider>
        <BrowserRouter history={history}>
          <WebsiteRouter />
        </BrowserRouter>
      </UserProvider>
    </SoccerProvider>
  );
}

export default App;
