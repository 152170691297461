import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import ScrollToTop from "./actions/ScrollToTop";

import { lazy, Suspense } from "react";

// Front end pages
import Home from "./features/frontend/pages/Home";
import About from "./features/frontend/pages/About";
import HowItWorks from "./features/frontend/pages/HowItWorks";
import Contact from "./features/frontend/pages/Contact";

// Components
import Sidebar from "./features/dashboard/Sidebar";
import ConditionalSidebar from "./layouts/ConditionalSidebar";
import Navbar from "./features/frontend/components/Navbar";
import Footer from "./features/frontend/components/Footer";

// Providers
import { QueryClientProvider, QueryClient, Query } from "react-query";

// export const DashboardRouter = () => {
//   // User Management
//   // const navigate = useNavigate();
//   // const [user, setUser] = useState(null);
//   // const [loading, setLoading] = useState(true);
//   // const [originalRoute, setOriginalRoute] = useState(null);

//   // useEffect(() => {
//   //   // Initialize the unsubscribe function outside the callback.
//   //   let unsubscribe;

//   //   // Attach the listener and store the unsubscribe function.
//   //   unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//   //     setUser(currentUser);

//   //     // Check if user is authenticated and redirect if not.
//   //     if (!currentUser) {
//   //       // Save the original route before redirecting to login
//   //       setOriginalRoute(window.location.pathname);
//   //       navigate("/account");
//   //     } else if (originalRoute) {
//   //       // If the user is logged in and an original route exists, redirect to it
//   //       navigate(originalRoute);
//   //       setOriginalRoute(null);
//   //     }

//   //     setLoading(false);
//   //   });

//   //   // Return the unsubscribe function from this effect.
//   //   return () => {
//   //     // Unsubscribe when the component unmounts.
//   //     if (unsubscribe) {
//   //       unsubscribe();
//   //     }
//   //   };
//   // }, [navigate, originalRoute]);

//   // Set Active User
//   const { isFetchingActiveUser } = useUserContext();

//   const CricketArtwork = CricketHoc(CricketInGameStats, CricketStandings);
//   const SoccerComponent = SoccerHoc(TopGoalScorerAssist, Media);

//   return (
//     <>
//       <QueryClientProvider client={queryClient}>
//         <div className="top-header">
//           <>
//             <>
//               <ConditionalSidebar>
//                 <Sidebar />
//               </ConditionalSidebar>

//               <Routes>
//                 <Route path="/" element={<LandingDashboard />} />
//                 <Route path="/account" element={<Account />} />
//                 <Route path="/account-details" element={<Register />} />
//                 <Route path="/media" element={<SoccerComponent />} />
//                 <Route
//                   path="/artwork-assignment"
//                   element={<ArtworkComponentAssignment />}
//                 />

//                 <Route
//                   path="/soccer/score-prediction"
//                   element={<InfluencerScore />}
//                 />
//                 <Route path="/soccer/interview" element={<Interview />} />
//                 <Route path="/soccer/fixtures" element={<LeagueFixtures />} />
//                 <Route
//                   path="/soccer/starting-11"
//                   element={<LeagueStartingEleven />}
//                 />
//                 <Route
//                   path="/soccer/score-update"
//                   element={<LeagueInGameUpates />}
//                 />
//                 <Route
//                   path="/soccer/team-match-stats"
//                   element={<LeagueTeamMatchStats />}
//                 />
//                 <Route
//                   path="/soccer/player-match-stats"
//                   element={<LeaguePlayerMatchStats />}
//                 />
//                 <Route
//                   path=":id/soccer/head-to-head"
//                   element={<HeadToHead />}
//                 />
//                 <Route
//                   path=":id/soccer/head-to-head-results"
//                   element={<HeadToHead />}
//                 />
//                 {/* <Route path="/soccer/team-profile" element={<TeamProfile />} /> */}
//                 <Route
//                   path="/soccer/player-profile"
//                   element={<PlayerProfile />}
//                 />
//                 <Route path="/soccer/standings" element={<LeagueStandings />} />
//                 <Route
//                   path="soccer/fixtures"
//                   element={
//                     <Suspense fallback={<CustomSpinner />}>
//                       <LeagueFixtures />
//                     </Suspense>
//                   }
//                 />
//                 <Route
//                   path=":id/soccer/team-profile"
//                   element={
//                     <Suspense fallback={<CustomSpinner />}>
//                       <TeamProfile />
//                     </Suspense>
//                   }
//                 />
//                 <Route path="soccer/live-fixtures" element={<LiveFixtures />} />
//                 <Route path="/fixtures" element={<Fixtures />} />
//                 <Route
//                   path=":id/soccer/top-goal-scorer"
//                   element={<SoccerComponent />}
//                 />
//                 <Route
//                   path=":id/soccer/top-assists"
//                   element={<SoccerComponent />}
//                 />
//                 <Route path="fixtures" element={<Fixtures />} />
//                 <Route
//                   path="/soccer/knockout-fixtures"
//                   element={<SoccerKnockoutStageFixtures />}
//                 />

//                 {/* Rugby */}
//                 <Route
//                   path="rugby/standings"
//                   element={<RugbyGroupStandings />}
//                 />
//                 <Route path="rugby/fixtures" element={<RugbyPoolFixtures />} />
//                 <Route
//                   path="rugby/world-cup/live-fixtures"
//                   element={<RugbyLiveFixtures />}
//                 />
//                 <Route
//                   path="rugby/world-cup/score-update"
//                   element={<LiveRugbyScore />}
//                 />

//                 <Route
//                   path="rugby/world-cup/player-match-stats"
//                   element={<RugbyPlayerInMatchStats />}
//                 />

//                 <Route
//                   path="rugby/head-to-head"
//                   element={<RugbyHeadToHead />}
//                 />

//                 <Route
//                   path="rugby/head-to-head-results"
//                   element={<RugbyHeadToHead />}
//                 />

//                 <Route
//                   path="rugby/knockout-fixtures"
//                   element={<RugbyKnockoutFixtures />}
//                 />

//                 {/* Cricket */}

//                 <Route
//                   path="cricket/standings"
//                   element={<CricketArtwork component={CricketStandings} />}
//                 />
//                 <Route
//                   path="cricket/innings"
//                   element={<CricketArtwork component={CricketInGameStats} />}
//                 />
//               </Routes>
//             </>
//           </>
//           {/* )} */}
//         </div>
//         <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
//       </QueryClientProvider>
//     </>
//   );
// };

export const WebsiteRouter = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};
