import { createContext, useEffect, useState } from "react";
import useFetchFirebase, { auth } from "./useFetchFirebase";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  // Define Firebase Database and Internal User Database
  const userData = useFetchFirebase("users");
  const firebaseDisplayName = auth?.currentUser?.displayName;

  // Define active user
  const [activeUser, setActiveUser] = useState(undefined);
  const [isFetchingActiveUser, setIsFetchingActiveUser] = useState(true);

  useEffect(() => {
    if (userData.data && firebaseDisplayName) {
      // Map Through user data
      const matchingUserKey = Object.keys(userData?.data).find(
        (key) => key === firebaseDisplayName
      );

      if (matchingUserKey) {
        // Define Matching User
        const matchingUser = userData.data[matchingUserKey];

        setActiveUser(matchingUser);
      }
      setIsFetchingActiveUser(false);
    }
  }, [userData, firebaseDisplayName]);

  // Define Active Menu & Routes
  const menuItems = [
    {
      section: "Pre-Game",
      pages: [
        {
          url: `${activeUser?.id}/soccer/head-to-head`,
          pageName: "Head-To-Head",
        },
        {
          url: `${activeUser?.id}/soccer/head-to-head-results`,
          pageName: "Head-To-Head Results",
        },
        { url: "/soccer/interview", pageName: "Interviews" },
        { url: "/soccer/score-prediction", pageName: "Score Prediction" },
      ],
    },
    {
      section: "In-Game",
      pages: [
        { url: "/soccer/score-update", pageName: "Score Update" },
        {
          url: "/soccer/team-match-stats",
          pageName: "Team Match Stats",
        },
        { url: "/soccer/player-match-stats", pageName: "Player Match Stats" },
      ],
    },
    {
      section: "League Updates",
      pages: [
        {
          url: "/soccer/standings",
          pageName: "League Standings",
        },
        {
          url: `${activeUser?.id}/soccer/team-profile`,
          pageName: "Team Profile",
        },
        { url: "/soccer/fixtures", pageName: "Fixtures" },
        { url: "/soccer/knockout-fixtures", pageName: "Knockout Fixtures" },
        { url: "/soccer/live-fixtures", pageName: "Live Fixtures" },
        {
          url: `/${activeUser?.id}/soccer/top-goal-scorer`,
          pageName: "Top Goal Scorers",
        },
        {
          url: `${activeUser?.id}/soccer/top-assists`,
          pageName: "Top Assists",
        },
      ],
    },
  ];

  const [activeMenuItems, setActiveMenuItems] = useState(undefined);

  useEffect(() => {
    if (activeUser) {
      const menuItemsList = Object.keys(
        activeUser?.sports?.dataFeeds?.artworks
      ).map((menuCategory) => {
        const matchingMenuItems = menuItems.find(
          (menuItem) => menuItem.section === menuCategory
        );

        return {
          section: matchingMenuItems.section,
          pages: matchingMenuItems.pages.filter((page) =>
            activeUser.sports.dataFeeds.artworks[menuCategory].includes(
              page.pageName
            )
          ),
        };
      });

      setActiveMenuItems(menuItemsList);
    }
  }, [activeUser]);

  return (
    <UserContext.Provider
      value={{ activeUser, isFetchingActiveUser, activeMenuItems }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
