import { createContext, useEffect, useState } from "react";
import useFetchFirebase from "./useFetchFirebase";

const SoccerContext = createContext({});

export const SoccerProvider = ({ children }) => {
  // Handle matchday squad
  const [soccerData, setSoccerData] = useState({
    channel: "",
    competition: "",
    venue: "",
    date: "",
    homeTeam: "",
    awayTeam: "",
    homePlayer: "",
    awayPlayer: "",
    sponsor: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSoccerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const handleSquadMemberInputChange = (e) => {
    const { name, value } = e.target;
    const [squadMemberName, number] = value.split(" - ");

    setSoccerData((prevData) => ({
      ...prevData,
      [name]: {
        name: squadMemberName,
        number: number,
      },
    }));

    e.target.value = "";
  };

  // / Define active Matchday Data
  // const { data:clubData} = useFetch('http://localhost:8000/clubs')
  // const { data:channelData} = useFetch('http://localhost:8000/channels')
  const { data: sponsorData } = useFetchFirebase("sponsors");
  const { data: clubData } = useFetchFirebase("clubs");
  const { data: channelData } = useFetchFirebase("channels");

  const [channelLogo, setChannelLogo] = useState("Channel_1.png");

  const [homeLogo, setHomeLogo] = useState("Kaizer_Chiefs.png");
  const [awayLogo, setAwayLogo] = useState("Orlando_Pirates.png");

  const [homeSquad, setHomeSquad] = useState("");
  const [awaySquad, setAwaySquad] = useState("");

  let [homeStartingEleven, setHomeStartingEleven] = useState([]);
  const [homeSubstitutes, setHomeSubstitutes] = useState("");
  const [awayStartingEleven, setAwayStartingEleven] = useState([]);
  const [awaySubstitutes, setAwaySubstitutes] = useState("");

  // Interview variables

  const [sponsorLogo, setSponsorLogo] = useState("");

  const [homeGoalScorer, setHomeGoalGoalscorer] = useState("");

  // Set Kick Off Date
  const [matchDate, setMatchDate] = useState(null);
  let formattedDate = "";
  let kickOff = "";

  const handleDateChange = (date) => {
    setMatchDate(date);
  };

  if (matchDate != null) {
    formattedDate = matchDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: false, // Use 24-hour format
      minute: "numeric",
    });

    kickOff = formattedDate.replace(/,|\bat\b/gi, "");
  }

  // Handle Interview
  const [homeInterviewLogo, setHomeInterviewLogo] =
    useState("Kaizer_Chiefs.png");
  const [awayInterviewLogo, setAwayInterviewLogo] = useState(
    "Orlando_Pirates.png"
  );

  const [homeInterviewSquad, setHomeInterviewSquad] = useState("");
  const [awayInterviewSquad, setAwayInterviewSquad] = useState("");

  const [interviewFixture, setInterviewFixture] = useState([]);

  const [interviewData, setInterviewData] = useState({
    homeTeam: "",
    awayTeam: "",
    intervieweeComments: "",
    intervieweeTeam: "",
    interviewee: {},
  });

  const handleInterviewInputChange = (e) => {
    const { name, value } = e.target;

    setInterviewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const handleIntervieweeInputChange = (e) => {
    const { name, value } = e.target;
    const [intervieweeName, position] = value.split(" - ");

    setInterviewData((prevData) => ({
      ...prevData,
      [name]: {
        name: intervieweeName,
        position: position,
      },
    }));

    e.target.value = "";
  };

  // Set Interview Active Fixtures and Fetch Players
  const handleInterviewFixture = (e) => {
    if (
      interviewData.homeTeam !== "" ||
      (interviewData.awayTeam !== "" && interviewFixture.length < 2)
    ) {
      setInterviewFixture((prevFixture) => [
        ...prevFixture,
        interviewData.homeTeam,
        interviewData.awayTeam,
      ]);
    }
  };

  // Handle goal scoring
  const [homeGoal, setHomeGoal] = useState({
    homeName: "",
    homeMinute: "",
    homeScore: "",
  });

  const [awayGoal, setAwayGoal] = useState({
    awayName: "",
    awayMinute: "",
    awayScore: "",
  });

  const [awayScore, setAwayScore] = useState(0);
  const [homeScore, setHomeScore] = useState(0);

  const [homeGoalScorers, setHomeGoalScorers] = useState("");
  const [awayGoalScorers, setAwayGoalScorers] = useState("");

  // Own Goal Logic
  const [isHomeOwnGoal, setisHomeOwnGoal] = useState("");
  const [isAwayOwnGoal, setisAwayOwnGoal] = useState("");

  // Goal Inputs
  const handleHomeInputChange = (e) => {
    const { name, value } = e.target;

    setHomeGoal((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const handleAwayInputChange = (e) => {
    const { name, value } = e.target;

    setAwayGoal((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  const handleHomeOGSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === "home-own-goal") {
      setisHomeOwnGoal(checked);
    }
  };

  const handleAwayOGSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === "away-own-goal") {
      setisAwayOwnGoal(checked);
    }
  };

  const handleAwayGoalScorer = () => {
    if (
      awayGoal.awayScore > 0 &&
      awayGoal.awayMinute !== "" &&
      awayGoal.awayName !== ""
    ) {
      if (isAwayOwnGoal === true) {
        setAwayGoalScorers((prevState) => [
          ...prevState,
          {
            name: "OG - " + awayGoal.awayName,
            minute: [awayGoal.awayMinute],
            score: awayGoal.awayScore,
          },
        ]);
      } else {
        setAwayGoalScorers((prevState) => [
          ...prevState,
          {
            name: awayGoal.awayName,
            minute: [awayGoal.awayMinute],
            score: awayGoal.awayScore,
          },
        ]);
      }

      setAwayGoal({
        awayName: "",
        awayMinute: "",
      });

      setAwayScore(awayGoal.awayScore);

      let matchingAwayScorer = awayGoalScorers.find(
        (item) => item.name === awayGoal.awayName
      );

      if (awayGoal.awayScore > 1 && matchingAwayScorer !== undefined) {
        matchingAwayScorer.minute.push(awayGoal.awayMinute);

        let duplicateCount = 0;
        let updatedAwayGoalScorers = awayGoalScorers.filter((item) => {
          if (item.name === awayGoal.awayName) {
            duplicateCount++;
            return duplicateCount <= 1;
          }
          return true;
        });
        setAwayGoalScorers(updatedAwayGoalScorers);

        setAwayGoal({
          awayName: "",
          awayMinute: "",
        });
      }
    }
  };

  const handleHomeGoalScorer = () => {
    if (
      homeGoal.homeScore > 0 &&
      homeGoal.awayMinute !== "" &&
      homeGoal.homeName !== ""
    ) {
      if (isHomeOwnGoal === true) {
        setHomeGoalScorers((prevState) => [
          ...prevState,
          {
            name: "OG - " + homeGoal.homeName,
            minute: [homeGoal.homeMinute],
            score: homeGoal.homeScore,
          },
        ]);
      } else {
        setHomeGoalScorers((prevState) => [
          ...prevState,
          {
            name: homeGoal.homeName,
            minute: [homeGoal.homeMinute],
            score: homeGoal.homeScore,
          },
        ]);
      }

      setHomeGoal({
        homeName: "",
        homeMinute: "",
      });

      setHomeScore(homeGoal.homeScore);

      let matchingHomeScorer = homeGoalScorers.find(
        (item) => item.name === homeGoal.homeName
      );

      if (homeGoal.homeScore > 1 && matchingHomeScorer !== undefined) {
        matchingHomeScorer.minute.push(homeGoal.homeMinute);

        let duplicateCount = 0;
        let updatedHomeGoalScorers = homeGoalScorers.filter((item) => {
          if (item.name === homeGoal.homeName) {
            duplicateCount++;
            return duplicateCount <= 1;
          }
          return true;
        });
        setHomeGoalScorers(updatedHomeGoalScorers);

        setHomeGoal({
          homeName: "",
          homeMinute: "",
        });
      }
    }
  };

  // Handle Live Score Time Switch
  const [isKickOff, setIsKickOff] = useState(false);
  const [isHalfTime, setIsHalfTime] = useState(false);
  const [isFullTime, setIsFullTime] = useState(false);

  const handleTimeSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === "kick-off") {
      setIsKickOff(checked);
      setIsHalfTime(false);
      setIsFullTime(false);
    } else if (name === "half-time") {
      setIsKickOff(false);
      setIsHalfTime(checked);
      setIsFullTime(false);
    } else if (name === "full-time") {
      setIsKickOff(false);
      setIsHalfTime(false);
      setIsFullTime(checked);
    }
  };

  // Variable change logic
  useEffect(() => {
    if (clubData) {
      // Set Matchday Clubs
      const matchingHomeClub = clubData.find(
        (item) => item.name === soccerData.homeTeam
      );
      const matchingAwayClub = clubData.find(
        (item) => item.name === soccerData.awayTeam
      );

      const matchingHomePlayer = clubData.find(
        (item) => item.name === soccerData.squad
      );

      if (matchingHomeClub) {
        setHomeLogo(matchingHomeClub.logo);
        setHomeSquad(matchingHomeClub.squad);
      }
      if (matchingAwayClub) {
        setAwayLogo(matchingAwayClub.logo);
        setAwaySquad(matchingAwayClub.squad);
      }

      // Set Interview Clubs and logo
      const matchingInterviewHomeClub = clubData.find(
        (item) => item.name === interviewData.homeTeam
      );
      const matchingInterviewAwayClub = clubData.find(
        (item) => item.name === interviewData.awayTeam
      );

      if (matchingInterviewHomeClub) {
        setHomeInterviewLogo(matchingInterviewHomeClub.logo);
        setHomeInterviewSquad(matchingInterviewHomeClub.squad);
      }
      if (matchingInterviewAwayClub) {
        setAwayInterviewLogo(matchingInterviewAwayClub.logo);
        setAwayInterviewSquad(matchingInterviewAwayClub.squad);
      }

      // Set homeStarting11 and homeSubs
      if (soccerData.homePlayer) {
        let hasDuplicate = false;

        if (Array.isArray(homeStartingEleven)) {
          hasDuplicate = homeStartingEleven.some(
            (player) =>
              player.name === soccerData.homePlayer && player.number === 16
          );
        }

        if (!hasDuplicate && homeStartingEleven.length < 11) {
          setHomeStartingEleven((prevState) => {
            const newHomePlayer = {
              name: soccerData.homePlayer.name,
              number: soccerData.homePlayer.number,
            };

            if (Array.isArray(prevState)) {
              const hasPlayer = prevState.some(
                (player) =>
                  player.name === newHomePlayer.name &&
                  player.number === newHomePlayer.number
              );

              if (hasPlayer) {
                return prevState; // Return previous state without adding the duplicate player
              } else {
                return [...prevState, newHomePlayer]; // Add the new player to the array
              }
            } else {
              return [newHomePlayer]; // Initialize the array with the new player if previous state is not an array
            }
          });
        } else if (homeStartingEleven.length >= 11) {
          setHomeSubstitutes((prevState) => {
            const newHomePlayer = {
              name: soccerData.homePlayer.name,
              number: soccerData.homePlayer.number,
            };

            if (Array.isArray(prevState)) {
              const hasPlayer = prevState.some(
                (player) =>
                  player.name === newHomePlayer.name &&
                  player.number === newHomePlayer.number
              );

              if (hasPlayer) {
                return prevState; // Return previous state without adding the duplicate player
              } else {
                return [...prevState, newHomePlayer]; // Add the new player to the array
              }
            } else {
              return [newHomePlayer]; // Initialize the array with the new player if previous state is not an array
            }
          });
        }
      }

      // Set awayStarting11 and awaySubs
      if (soccerData.awayPlayer) {
        let hasDuplicate = false;

        if (Array.isArray(awayStartingEleven)) {
          hasDuplicate = awayStartingEleven.some(
            (player) =>
              player.name === soccerData.awayPlayer && player.number === 16
          );
        }

        if (!hasDuplicate && awayStartingEleven.length < 11) {
          setAwayStartingEleven((prevState) => {
            const newAwayPlayer = {
              name: soccerData.awayPlayer.name,
              number: soccerData.awayPlayer.number,
            };

            if (Array.isArray(prevState)) {
              const hasPlayer = prevState.some(
                (player) =>
                  player.name === newAwayPlayer.name &&
                  player.number === newAwayPlayer.number
              );

              if (hasPlayer) {
                return prevState; // Return previous state without adding the duplicate player
              } else {
                return [...prevState, newAwayPlayer]; // Add the new player to the array
              }
            } else {
              return [newAwayPlayer]; // Initialize the array with the new player if previous state is not an array
            }
          });
        } else if (awayStartingEleven.length >= 11) {
          setAwaySubstitutes((prevState) => {
            const newAwayPlayer = {
              name: soccerData.awayPlayer.name,
              number: soccerData.awayPlayer.number,
            };

            if (Array.isArray(prevState)) {
              const hasPlayer = prevState.some(
                (player) =>
                  player.name === newAwayPlayer.name &&
                  player.number === newAwayPlayer.number
              );

              if (hasPlayer) {
                return prevState; // Return previous state without adding the duplicate player
              } else {
                return [...prevState, newAwayPlayer]; // Add the new player to the array
              }
            } else {
              return [newAwayPlayer]; // Initialize the array with the new player if previous state is not an array
            }
          });
        }
      }
    }

    if (channelData) {
      const matchingChannel = channelData.find(
        (item) => item.name === soccerData.channel
      );

      if (matchingChannel) {
        setChannelLogo(matchingChannel.logo);
      }
    }

    if (sponsorData) {
      const matchingSponsor = sponsorData.find(
        (item) => item.name === soccerData.sponsor
      );

      if (soccerData.sponsor) {
        // Check if sponsorLogo is an array
        if (Array.isArray(sponsorLogo)) {
          // Check if the sponsor logo already exists in the state
          const isDuplicate = sponsorLogo.some(
            (item) => item.sponsorLogo === matchingSponsor.logo
          );

          if (!isDuplicate) {
            setSponsorLogo((prevState) => [
              ...prevState,
              {
                sponsorLogo: matchingSponsor.logo,
              },
            ]);
          }
        } else {
          // If sponsorLogo is not an array, handle accordingly
          setSponsorLogo([{ sponsorLogo: matchingSponsor.logo }]);
        }
      }
    }

    //   Set homeGoalScore
    if (soccerData.homeScore > 0) {
      setHomeGoalGoalscorer((prevState) => [
        ...prevState,
        {
          name: soccerData.homeGoalScorer,
          minute: soccerData.homeGoalMinute,
        },
      ]);
    }
  }, [
    clubData,
    channelData,
    soccerData.awayTeam,
    soccerData.homeTeam,
    soccerData.homePlayer,
    soccerData.awayPlayer,
    soccerData.channel,
    soccerData.sponsor,
    setHomeLogo,
    setHomeSquad,
    setAwayLogo,
    setChannelLogo,
    setHomeStartingEleven,
    setHomeGoalGoalscorer,
    setHomeSubstitutes,
    setAwayStartingEleven,
    setSponsorLogo,
    // Handle Interview
    interviewData.homeTeam,
    interviewData.awayTeam,
    setHomeInterviewLogo,
    setHomeInterviewSquad,
    setAwayInterviewLogo,
    setAwayInterviewSquad,
  ]);

  const [subData, setSubData] = useState({
    homePlayerOut: "",
    homePlayerIn: "",
    awayPlayerOut: "",
    awayPlayerIn: "",
  });

  const [homeSubsIn, setHomeSubsIn] = useState([]);
  const [awaySubsIn, setAwaySubsIn] = useState([]);
  const [homeSubsOut, setHomeSubsOut] = useState([]);
  const [awaySubsOut, setAwaySubsOut] = useState([]);

  // Handle Substitutions
  const handleSubsInputChange = (e) => {
    const { name, value } = e.target;
    const [playerName, playerNumber] = value.split(" - ");

    setSubData((prevData) => ({
      ...prevData,
      [name]: {
        name: playerName,
        number: playerNumber,
      },
    }));

    e.target.value = "";
  };

  const handleHomeSubs = () => {
    setHomeSubsIn((prevState) => [
      ...prevState,
      {
        name: subData.homePlayerIn.name,
        number: subData.homePlayerIn.number,
      },
    ]);

    setHomeSubsOut((prevState) => [
      ...prevState,
      {
        name: subData.homePlayerOut.name,
        number: subData.homePlayerOut.number,
      },
    ]);

    setHomeStartingEleven((prevStartingEleven) => {
      const updatedStartingEleven = prevStartingEleven.map((player) => {
        const substitution = homeSubsOut.find(
          (substitute) => substitute.name === player.name
        );
        if (substitution) {
          return homeSubsIn.find(
            (replacement) => replacement.number === substitution.number
          );
        }
        return player;
      });
      return updatedStartingEleven;
    });

    setSubData((prevData) => ({
      ...prevData,
      homePlayerIn: "",
      homePlayerOut: "",
    }));
  };

  const handleAwaySubs = () => {
    setAwaySubsIn((prevState) => [
      ...prevState,
      {
        name: subData.awayPlayerIn.name,
        number: subData.awayPlayerIn.number,
      },
    ]);

    setAwaySubsOut((prevState) => [
      ...prevState,
      {
        name: subData.awayPlayerOut.name,
        number: subData.awayPlayerOut.number,
      },
    ]);
  };
  return (
    <SoccerContext.Provider
      value={{
        awayLogo,
        awayStartingEleven,
        channelLogo,
        handleInputChange,
        homeLogo,
        homeSquad,
        awaySquad,
        homeStartingEleven,
        homeSubstitutes,
        awaySubstitutes,
        setSoccerData,
        soccerData,
        sponsorLogo,
        matchDate,
        setMatchDate,
        handleDateChange,
        kickOff,
        // Handle Subs
        subData,
        handleSubsInputChange,
        handleHomeSubs,
        handleAwaySubs,
        homeSubsIn,
        homeSubsOut,
        awaySubsIn,
        awaySubsOut,
        // Goal Scoring variables
        handleAwayInputChange,
        handleHomeInputChange,
        handleAwayGoalScorer,
        handleHomeGoalScorer,
        handleTimeSwitchChange,
        handleHomeOGSwitchChange,
        handleAwayOGSwitchChange,
        awayGoalScorers,
        homeGoalScorers,
        isHomeOwnGoal,
        isAwayOwnGoal,
        awayGoal,
        homeGoal,
        awayScore,
        homeScore,
        isKickOff,
        isFullTime,
        isHalfTime,
        // Handle Interview
        homeInterviewLogo,
        homeInterviewSquad,
        awayInterviewLogo,
        awayInterviewSquad,
        interviewData,
        handleInterviewInputChange,
        handleIntervieweeInputChange,
        interviewFixture,
        handleInterviewFixture,
        handleSquadMemberInputChange,
      }}
    >
      {children}
    </SoccerContext.Provider>
  );
};

export default SoccerContext;
